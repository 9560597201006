import React, { useEffect, useState } from 'react';
import './style.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { RJTECHLABS_URLS } from '../../utills/url';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import YourComponent from './test';
import CommonModal from '../commonModal';
import EnrollNow from '../EnrollNow';



const Header = () => {
	const navigate = useNavigate();
	const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

	return (
		<>
			<header class="header">
				<div class="container-fluid">
					<nav class="navigation">
						<div class="navigation-wrapper">
							<a href="/" class="d-flex align-items-center">
								{/* <h3 className='text-16 lightest-gray'>	RJ Tech Labs </h3> */}
								<img src="assets/media/rjlogos.png" alt="/logo" class="" width={70} height={100} style={{}}/>
							</a>
							<div class="main-menu__nav">
								<ul class="main-menu__list " style={{ fontSize: '200px' }}>
									<li className='pointer'>
										<a data-path="/" href='/' onClick={() => navigate('/')}>Home</a>
									</li>
									<li className='pointer'>
										<a data-path="/aboutUs" className='pointer' onClick={() => navigate('/aboutUs')}>About Us</a>
									</li>
									<li className='pointer'>
										<a data-path="/courses" onClick={() => navigate('/courses')}>Our Courses</a>
									</li>
									<li className='pointer'>
										<a data-path="/WorkShops" onClick={() => navigate('/WorkShops')}>Work Shop</a>
									</li >
									<li className='pointer'>
										<a data-path="/Internships" onClick={() => navigate('/Internships')}>Internships</a>
									</li>
									<li className='pointer'>
										<a data-path="/Projects" onClick={() => navigate('/Projects')}>Projects</a>
									</li>
									<li className='pointer'>
										<a data-path="/comingSoon" onClick={() => navigate('/comingSoon')}>Interviews</a>
									</li>
									<li className='pointer'>
										<a data-path="/contact" onClick={() => navigate('/contact')}>Contact</a>
									</li>
								</ul>
							</div>
							<div class="header-right-block">
								<a class="cus-btn transparent text-16"  onClick={() => {
									openModal();
								}}>
									Enroll Now
								</a>
								<a class="d-xl-none d-flex main-menu__toggler mobile-nav__toggler">
									{/* <img src="assets/media/icon/menu-2.png" alt="" /> */}
									<i class="fa-solid fa-bars" ></i>
								</a>
							</div>
						</div>
					</nav>
				</div>
			</header>

			<div class="mobile-nav__wrapper">
				<div class="mobile-nav__overlay mobile-nav__toggler"></div>
				<div class="mobile-nav__content">
					<span class="mobile-nav__close mobile-nav__toggler"><i class="fa fa-times"></i></span>
					<div class="logo-box">
						<a href='/' aria-label="logo image">
							<p>RJ Tech Labs</p></a>
						{/* <img src="assets/media/mobile-logo.png" alt="" /></a> */}
					</div>
					<div class="mobile-nav__container"></div>
					<ul class="mobile-nav__contact list-unstyled">
						<li>
							<i class="fas fa-envelope"></i>
							<a href="mailto:info@rjtechlabs.com">info@rjtechlabs.com</a>
						</li>
						<li>
							<i class="fa fa-phone-alt"></i>
							<a href="tel:9618366575">9618366575</a>
							<a href="tel:9885453033">9885453033</a>
						</li>
					</ul>
					<div class="mobile-nav__social">
						<a href="https://twitter.com/"><i class="fab fa-twitter"></i></a>
						<a href="https://www.facebook.com/"><i class="fab fa-facebook"></i></a>
						<a href="https://www.instagram.com/"><i class="fab fa-instagram"></i></a>
					</div>
				</div>
			</div>
			<CommonModal isOpen={isModalOpen} onClose={closeModal}>
				<EnrollNow closeModal={closeModal}/>
			</CommonModal>	
		</>
	)
}

export default Header;