import React from 'react'

const Students = () => {
    return (
        <section class="testimonial py-80">
            <div class="container-fluid">
                <div class="d-flex flex-column align-items-center gap-8 text-center mb-48">
                    <h5 class="text-md text-uppercase">Students Love Notes</h5>
                    <h2 class="black font-primary fw-600">Words from Our Happy Students</h2>
                    <p class="light-gray">Lorem ipsum dolor sit amet consectetur. Pellentesque aliquet in risus <br />
                        phasellus tellus non est.</p>
                </div>
                <div class="elfsight-app-b9f01a61-ac98-4c6a-8b89-dc7b420395b8" data-elfsight-app-lazy ></div>
                {/* <div class="row justify-content-center">
                    <div class="col-xl-10 col-lg-11">
                        <div class="testimonial-wrapper">
                            <div class="row align-items-center row-gap-4">
                                <div class="col-lg-6">
                                    <div class="testimonial-image-slider">
                                        <div class="image-box">
                                            <img src="assets/media/testimonials/t-image-1.png" alt="" class="m-auto" />
                                        </div>
                                        <div class="image-box">
                                            <img src="assets/media/testimonials/t-image-2.png" alt="" class="m-auto" />
                                        </div>
                                        <div class="image-box">
                                            <img src="assets/media/testimonials/t-image-3.png" alt="" class="m-auto" />
                                        </div>
                                        <div class="image-box">
                                            <img src="assets/media/testimonials/t-image-4.png" alt="" class="m-auto" />
                                        </div>
                                        <div class="image-box">
                                            <img src="assets/media/testimonials/t-image-5.png" alt="" class="m-auto" />
                                        </div>
                                    </div>
                                    <div class="all-navboxes">
                                        <div class="testimonial-asNav-slider">
                                            <div class="nav-box">
                                                <img src="assets/media/testimonials/nav-1.png" alt="" />
                                            </div>
                                            <div class="nav-box">
                                                <img src="assets/media/testimonials/nav-2.png" alt="" />
                                            </div>
                                            <div class="nav-box">
                                                <img src="assets/media/testimonials/nav-3.png" alt="" />
                                            </div>
                                            <div class="nav-box">
                                                <img src="assets/media/testimonials/nav-4.png" alt="" />
                                            </div>
                                            <div class="nav-box">
                                                <img src="assets/media/testimonials/nav-5.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="testimonial-text-slider">
                                        <div class="content-box">
                                            <div class="quotes">
                                                <img src="assets/media/icon/quote.png" alt="" />
                                            </div>
                                            <div class="review-block">
                                                <div class="review-text mb-24">
                                                    <p class="black">Lorem ipsum dolor sit amet consectetur. Eget
                                                        ullamcorper sit lacus pretium blandit ultrices hendrerit arcu.
                                                        Dignissim
                                                        volutpat cursus magnis ante ultrices cras lacus et nibh. Urna
                                                        convallis.</p>
                                                </div>
                                                <div class="rating-box">
                                                    <div class="mb-16">
                                                        <i class="fa-solid fa-star-sharp color-sec"></i>
                                                        <i class="fa-solid fa-star-sharp color-sec"></i>
                                                        <i class="fa-solid fa-star-sharp color-sec"></i>
                                                        <i class="fa-solid fa-star-sharp color-sec"></i>
                                                        <i class="fa-solid fa-star-sharp color-sec"></i>
                                                    </div>
                                                    <div class="person-info d-flex mb-4p">
                                                        <div>
                                                            <p class="color-white mb-4p">Olivia Anderson</p>
                                                            <p class="color-sec">Maker</p>
                                                        </div>
                                                        <div class="rate d-flex align-items-center gap-1">
                                                            <i class="fa-solid fa-star-sharp color-primary"></i>
                                                            <p class="color-primary">4.8</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="content-box">
                                            <div class="quotes">
                                                <img src="assets/media/icon/quote.png" alt="" />
                                            </div>
                                            <div class="review-block">
                                                <div class="review-text mb-24">
                                                    <p class="black">Lorem ipsum dolor sit amet consectetur. Eget
                                                        ullamcorper sit lacus pretium blandit ultrices hendrerit arcu.
                                                        Dignissim
                                                        volutpat cursus magnis ante ultrices cras lacus et nibh. Urna
                                                        convallis.</p>
                                                </div>
                                                <div class="rating-box">
                                                    <div class="mb-16">
                                                        <i class="fa-solid fa-star-sharp color-sec"></i>
                                                        <i class="fa-solid fa-star-sharp color-sec"></i>
                                                        <i class="fa-solid fa-star-sharp color-sec"></i>
                                                        <i class="fa-solid fa-star-sharp color-sec"></i>
                                                        <i class="fa-solid fa-star-sharp color-sec"></i>
                                                    </div>
                                                    <div class="person-info d-flex mb-4p">
                                                        <div>
                                                            <p class="color-white mb-4p">Catherine Nickson</p>
                                                            <p class="color-sec">Visiter</p>
                                                        </div>
                                                        <div class="rate d-flex align-items-center gap-1">
                                                            <i class="fa-solid fa-star-sharp color-primary"></i>
                                                            <p class="color-primary">4.8</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="content-box">
                                            <div class="quotes">
                                                <img src="assets/media/icon/quote.png" alt="" />
                                            </div>
                                            <div class="review-block">
                                                <div class="review-text mb-24">
                                                    <p class="black">Lorem ipsum dolor sit amet consectetur. Eget
                                                        ullamcorper sit lacus pretium blandit ultrices hendrerit arcu.
                                                        Dignissim
                                                        volutpat cursus magnis ante ultrices cras lacus et nibh. Urna
                                                        convallis.</p>
                                                </div>
                                                <div class="rating-box">
                                                    <div class="mb-16">
                                                        <i class="fa-solid fa-star-sharp color-sec"></i>
                                                        <i class="fa-solid fa-star-sharp color-sec"></i>
                                                        <i class="fa-solid fa-star-sharp color-sec"></i>
                                                        <i class="fa-solid fa-star-sharp color-sec"></i>
                                                        <i class="fa-solid fa-star-sharp color-sec"></i>
                                                    </div>
                                                    <div class="person-info d-flex mb-4p">
                                                        <div>
                                                            <p class="color-white mb-4p">Linda James</p>
                                                            <p class="color-sec">Customer</p>
                                                        </div>
                                                        <div class="rate d-flex align-items-center gap-1">
                                                            <i class="fa-solid fa-star-sharp color-primary"></i>
                                                            <p class="color-primary">4.8</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="content-box">
                                            <div class="quotes">
                                                <img src="assets/media/icon/quote.png" alt="" />
                                            </div>
                                            <div class="review-block">
                                                <div class="review-text mb-24">
                                                    <p class="black">Lorem ipsum dolor sit amet consectetur. Eget
                                                        ullamcorper sit lacus pretium blandit ultrices hendrerit arcu.
                                                        Dignissim
                                                        volutpat cursus magnis ante ultrices cras lacus et nibh. Urna
                                                        convallis.</p>
                                                </div>
                                                <div class="rating-box">
                                                    <div class="mb-16">
                                                        <i class="fa-solid fa-star-sharp color-sec"></i>
                                                        <i class="fa-solid fa-star-sharp color-sec"></i>
                                                        <i class="fa-solid fa-star-sharp color-sec"></i>
                                                        <i class="fa-solid fa-star-sharp color-sec"></i>
                                                        <i class="fa-solid fa-star-sharp color-sec"></i>
                                                    </div>
                                                    <div class="person-info d-flex mb-4p">
                                                        <div>
                                                            <p class="color-white mb-4p">Chenal David</p>
                                                            <p class="color-sec">Maker</p>
                                                        </div>
                                                        <div class="rate d-flex align-items-center gap-1">
                                                            <i class="fa-solid fa-star-sharp color-primary"></i>
                                                            <p class="color-primary">4.8</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="content-box">
                                            <div class="quotes">
                                                <img src="assets/media/icon/quote.png" alt="" />
                                            </div>
                                            <div class="review-block">
                                                <div class="review-text mb-24">
                                                    <p class="black">Lorem ipsum dolor sit amet consectetur. Eget
                                                        ullamcorper sit lacus pretium blandit ultrices hendrerit arcu.
                                                        Dignissim
                                                        volutpat cursus magnis ante ultrices cras lacus et nibh. Urna
                                                        convallis.</p>
                                                </div>
                                                <div class="rating-box">
                                                    <div class="mb-16">
                                                        <i class="fa-solid fa-star-sharp color-sec"></i>
                                                        <i class="fa-solid fa-star-sharp color-sec"></i>
                                                        <i class="fa-solid fa-star-sharp color-sec"></i>
                                                        <i class="fa-solid fa-star-sharp color-sec"></i>
                                                        <i class="fa-solid fa-star-sharp color-sec"></i>
                                                    </div>
                                                    <div class="person-info d-flex mb-4p">
                                                        <div>
                                                            <p class="color-white mb-4p">Emily Johnson</p>
                                                            <p class="color-sec">Maker</p>
                                                        </div>
                                                        <div class="rate d-flex align-items-center gap-1">
                                                            <i class="fa-solid fa-star-sharp color-primary"></i>
                                                            <p class="color-primary">4.8</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="testimonial-image">
                                        <img src="assets/media/about/team.jpg" className='rounded rounded-pill' alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </section>
    )
}

export default Students