import React, { useState, useEffect } from 'react';

const ScrollToTop = () => {
    const [isVisible, setIsVisible] = useState(false);

    // Show button when page is scrolled down more than 300px
    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Scroll the page back to top smoothly
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    // Set up an event listener for scroll event
    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    return (
        <>
            {isVisible && (
                <button
                    onClick={scrollToTop}
                    style={styles.scrollToTopBtn}>
                    ↑
                </button>
            )}
        </>
    );
};

// Inline styling for the button
const styles = {
    scrollToTopBtn: {
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        backgroundColor: '#4688F4',
        color: 'white',
        border: 'none',
        padding: '10px 15px',
        borderRadius: '30px',
        cursor: 'pointer',
        fontSize: '15px',
        zIndex: 1000,
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
    }
};

export default ScrollToTop;
