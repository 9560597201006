import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import YouTube from "react-youtube";
import Modal from 'react-modal';
import CommonModal from '../../components/commonModal';
import EnrollNow from '../../components/EnrollNow';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '35% !important'
    },
};
const CoursesDetails = () => {
    // const [value, setValue] = useState(false);  

    const navigate = useNavigate();
    const Location = useLocation();
    const [modalIsOpenRegForMem, setIsOpenRegForMem] = React.useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const opts = {
        width: "100%",
        borderRadius: "2rem",
        playerVars: { autoplay: 1 },
    };
    const videoReady = (event) => {
        event.target.pauseVideo();
    };

    console.log("Location", Location?.state);

    const shareOnFacebook = () => {
        const url = window.location.href; // Get current page URL
        const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent('http://academy.rjtechlabs.com')}`;
        window.open(facebookShareUrl, '_blank');
    }
    const shareOnLinkedIn = () => {
        const url = window.location.href; // Get current page URL
        const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent('http://academy.rjtechlabs.com')}`;
        window.open(linkedInShareUrl, '_blank');
    }

    return (
        <>
            <section class="title-banner text-center">
                <div class="container-fluid">
                    <h1 class="color-white fw-400 text-capitalize">{Location?.state?.BannerText}</h1>
                </div>
            </section>
            <section>
                <div className='mt-4'>
                    <marquee>
                        <h5 className='color-sec'>  New batches started today, hurry up to contact us now!</h5>
                    </marquee>
                </div>
            </section>
            <section class="special py-80">
                <div class="container-fluid">
                    <div class="row row-gap-4">
                        <div class="col-xl-8 order-xl-2 order-1">
                            <h5 class="black ">{Location?.state?.CoursesName} : {Location?.state?.CoursesDescrption}</h5>
                            <div class="rating mt-3">
                                <i class="fa-solid fa-star-sharp color-sec"></i>
                                <i class="fa-solid fa-star-sharp color-sec"></i>
                                <i class="fa-solid fa-star-sharp color-sec"></i>
                                <i class="fa-solid fa-star-sharp color-sec"></i>
                                <i class="fa-solid fa-star-sharp color-sec"></i>
                                <p class="black mt-3">{Location?.state?.CourseRating}( Ratings)</p>
                            </div>
                            {/* <div>
                                <a class="black fw-400">Categories: Affiliate Marketing, Email Marketing, Marketing, SEO</a>
                            </div> */}
                        </div>
                        <div class="col-xl-4 order-xl-2 order-1">
                            <a class="black ">  <i class="fa-solid fa-share color-sec"></i> Share  </a>&nbsp;&nbsp;&nbsp;
                            <img loading="lazy" className='pointer' decoding="async" src="../assets/media/icons8-facebook.svg" alt="Facebook" width="40" height="40" onClick={() => {
                                shareOnFacebook()
                            }} />
                            <img loading="lazy" className='pointer' decoding="async" src="../assets/media/icons8-linkedin.svg" alt="LinkedIn" width="40" height="40" onClick={() => {
                                shareOnLinkedIn()
                            }} />
                        </div>
                    </div>
                    <div class="row row-gap-4">
                        <div class="col-xl-9 order-xl-1 order-1">
                            <div class="row row-gap-4">
                                <div class="col-xl-12 order-xl-12 order-1">
                                    <div
                                        style={{
                                            maxWidth: "800px",
                                            margin: "auto",
                                            marginTop: "12px",
                                            minHeight: "30vh",
                                            borderRadius: "12px",
                                            overflow: "hidden",
                                        }}
                                    >
                                        <YouTube
                                            videoId={Location?.state?.CourseYoutubeVideoLink}
                                            opts={opts}
                                            onReady={videoReady}
                                        />
                                    </div>
                                </div>
                                <div class="col-xl-12 order-xl-12 order-2 menu">
                                    <section class="product-description ">
                                        <div class="row">
                                            <div class="col-lg-10">
                                                <div class="description-wrapper br-20">
                                                    <nav class="mb-48">
                                                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                            {/* <button class="nav-link button-1" id="nav-desc-tab" data-bs-toggle="tab"
                                                                data-bs-target="#nav-desc" type="button" role="tab" aria-controls="nav-desc"
                                                                aria-selected="true"> Reviews (02)</button> */}
                                                            {/* <button class="nav-link button-2 active" id="nav-review-tab" data-bs-toggle="tab"
                                                                data-bs-target="#nav-review" type="button" role="tab" aria-controls="nav-review"
                                                                aria-selected="false"> Course Info</button> */}
                                                        </div>
                                                    </nav>
                                                    <div class="tab-content" id="nav-review">
                                                        <div class="tab-pane fade" id="nav-desc" role="tabpanel"
                                                            aria-labelledby="nav-desc-tab">
                                                        </div>
                                                        <div class="tab-pane fade show active" id="nav-tabContent" role="tabpanel"
                                                            aria-labelledby="nav-review-tab">
                                                            <div class="review-area">
                                                                <h3 class="black font-primary fw-600 mb-16">About Course</h3>
                                                                <p class="black fw-300 mb-12">
                                                                    {Location?.state?.CoursesDescrptionOne}
                                                                </p>
                                                                <p class="black fw-300 mb-32">
                                                                    {Location?.state?.CoursesDescrptionTwo}
                                                                </p>
                                                                <p class="black fw-300">
                                                                    {Location?.state?.CoursesDescrptionThree}
                                                                </p>
                                                                <div class="col-xl-12 order-xl-12 order-3 mt-3">
                                                                    <div class="">
                                                                        <h5 class=" black">
                                                                            What Will You Learn?		</h5>
                                                                        <ul class="tutor-course-details-widget-list tutor-color-black tutor-fs-6 tutor-m-0 tutor-mt-16">
                                                                            {Location?.state?.WhatWillYouLearn && Location?.state?.WhatWillYouLearn != 0 && Location?.state?.WhatWillYouLearn.map((item, index) => {
                                                                                return <li class="tutor-d-flex tutor-mb-12" key={index + 1}>
                                                                                    <span class=" black">{item?.points}</span>
                                                                                </li>
                                                                            })}

                                                                        </ul>
                                                                    </div>
                                                                </div>

                                                                <div class="col-xl-12 order-xl-12 order-4 mt-3">
                                                                    <h5 class="black ">Frequently Asked Questions</h5>
                                                                    <div class="accordion mt-3" id="accordionPanelsStayOpenExample">
                                                                        <div class="accordion-item">
                                                                            <h2 class="accordion-header">
                                                                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                                                                    Is Tosca easy to learn?
                                                                                </button>
                                                                            </h2>
                                                                            <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show">
                                                                                <div class="accordion-body">
                                                                                    <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="accordion-item">
                                                                            <h2 class="accordion-header">
                                                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                                                                    Who should take the Tosca course?
                                                                                </button>
                                                                            </h2>
                                                                            <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse">
                                                                                <div class="accordion-body">
                                                                                    <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="accordion-item">
                                                                            <h2 class="accordion-header">
                                                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                                                                    How do I start learning Tosca?
                                                                                </button>
                                                                            </h2>
                                                                            <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse">
                                                                                <div class="accordion-body">
                                                                                    <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {/* <div class="row">
                                                                    <div class="col-lg-8">
                                                                        <h5 class="color-white mb-32">3 Reviews</h5>
                                                                        <div class="review-block mb-24">
                                                                            <div class="image-box">
                                                                                <img src="assets/media/user/user-1.png" alt="" />
                                                                            </div>
                                                                            <div class="text-box">
                                                                                <div>
                                                                                    <p class="color-sec fw-300 text-thin mb-4p">By: Olivia</p>
                                                                                    <p class="color-sec fw-300">20 Nov, 2024 </p>
                                                                                </div>
                                                                                <p class="black mb-16">Lorem ipsum dolor sit amet consectetur. Neque dictum gravida nunc euismod. Nulla
                                                                                    dolor rutrum consectetur purus proin ut. Cras cursus nullam erat at aliquet luctus et purus urna.</p>
                                                                                <a href="#" class="fw-400 color-white">Reply</a>
                                                                            </div>
                                                                        </div>
                                                                        <div class="review-block block-2 mb-32">
                                                                            <div class="image-box">
                                                                                <img src="assets/media/user/user-2.png" alt="" />
                                                                            </div>
                                                                            <div class="text-box">
                                                                                <div>
                                                                                    <p class="color-sec fw-300 text-thin mb-4p">By: Olivia</p>
                                                                                    <p class="color-sec fw-300">20 Nov, 2024 </p>
                                                                                </div>
                                                                                <p class="black mb-16">Lorem ipsum dolor sit amet consectetur. Neque dictum gravida nunc euismod. Nulla
                                                                                    dolor rutrum consectetur purus proin ut. Cras cursus nullam erat at aliquet luctus et purus urna.</p>
                                                                                <a href="#" class="fw-400 color-white">Reply</a>
                                                                            </div>
                                                                        </div>
                                                                        <div class="review-block mb-16">
                                                                            <div class="image-box">
                                                                                <img src="assets/media/user/user-1.png" alt="" />
                                                                            </div>
                                                                            <div class="text-box">
                                                                                <div>
                                                                                    <p class="color-sec fw-300 text-thin mb-4p">By: Olivia</p>
                                                                                    <p class="color-sec fw-300">20 Nov, 2024 </p>
                                                                                </div>
                                                                                <p class="black mb-16">Lorem ipsum dolor sit amet consectetur. Neque dictum gravida nunc euismod. Nulla
                                                                                    dolor rutrum consectetur purus proin ut. Cras cursus nullam erat at aliquet luctus et purus urna.</p>
                                                                                <a href="#" class="fw-400 color-white">Reply</a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-4">
                                                                        <h5 class="color-white mb-32">Write a Review</h5>
                                                                        <form action="https://uiparadox.co.uk/public/templates/caffe-craze/shop-list-sidebar.html" method="post" class="form-group contact-form">
                                                                            <div class="row">
                                                                                <div class="col-md-12">
                                                                                    <div class="input-block mb-16">
                                                                                        <input type="text" id="name" name="name" class="form-control form-2" placeholder="Your Name" required />
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                            <path
                                                                                                d="M10 0C7.09223 0 4.72656 2.36566 4.72656 5.27344C4.72656 8.18121 7.09223 10.5469 10 10.5469C12.9078 10.5469 15.2734 8.18121 15.2734 5.27344C15.2734 2.36566 12.9078 0 10 0ZM10 9.375C7.7384 9.375 5.89844 7.53504 5.89844 5.27344C5.89844 3.01184 7.7384 1.17188 10 1.17188C12.2616 1.17188 14.1016 3.01184 14.1016 5.27344C14.1016 7.53504 12.2616 9.375 10 9.375Z"
                                                                                                fill="#EAEAEC" />
                                                                                            <path
                                                                                                d="M16.5612 13.992C15.1174 12.5261 13.2035 11.7188 11.1719 11.7188H8.82812C6.79656 11.7188 4.88258 12.5261 3.43883 13.992C2.00215 15.4507 1.21094 17.3763 1.21094 19.4141C1.21094 19.7377 1.47328 20 1.79688 20H18.2031C18.5267 20 18.7891 19.7377 18.7891 19.4141C18.7891 17.3763 17.9979 15.4507 16.5612 13.992ZM2.40859 18.8281C2.70215 15.5045 5.46918 12.8906 8.82812 12.8906H11.1719C14.5308 12.8906 17.2979 15.5045 17.5914 18.8281H2.40859Z"
                                                                                                fill="#EAEAEC" />
                                                                                        </svg>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-md-12">
                                                                                    <div class="input-block mb-16">
                                                                                        <input type="email" id="mail" name="email" class="form-control form-2" placeholder="Your Email" required />
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                            <path
                                                                                                d="M18.2422 2.96875H1.75781C0.786602 2.96875 0 3.76023 0 4.72656V15.2734C0 16.2455 0.792383 17.0312 1.75781 17.0312H18.2422C19.2053 17.0312 20 16.2488 20 15.2734V4.72656C20 3.76195 19.2165 2.96875 18.2422 2.96875ZM17.996 4.14062C17.6369 4.49785 11.4564 10.6458 11.243 10.8581C10.9109 11.1901 10.4695 11.3729 10 11.3729C9.53047 11.3729 9.08906 11.1901 8.75594 10.857C8.61242 10.7142 2.50012 4.63414 2.00398 4.14062H17.996ZM1.17188 15.0349V4.96582L6.23586 10.0031L1.17188 15.0349ZM2.00473 15.8594L7.06672 10.8296L7.9284 11.6867C8.48176 12.2401 9.21746 12.5448 10 12.5448C10.7825 12.5448 11.5182 12.2401 12.0705 11.6878L12.9333 10.8296L17.9953 15.8594H2.00473ZM18.8281 15.0349L13.7641 10.0031L18.8281 4.96582V15.0349Z"
                                                                                                fill="#EAEAEC" />
                                                                                        </svg>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-md-12">
                                                                                    <textarea name="message" id="comment" cols="30" rows="5" class="form-control form-2 mb-32"
                                                                                        placeholder="Write Your Comments..."></textarea>
                                                                                </div>
                                                                                <div class="col-md-6">
                                                                                    <button type="submit" class="cus-btn primary text-uppercase">SUBMIT</button>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>

                            </div>
                        </div>
                        <div class="col-xl-3 order-xl-2 order-1">
                            <div class="sidebar reservation-form form-container bg-dark-black">
                                <div class="filter-block box-1 mb-24">
                                    <div class="title mb-24" data-count="1">
                                        <p class="black text-uppercase"> {Location?.state?.CoursesName}</p> <br />
                                    </div>
                                    <div className='d-flex gap-4'>
                                        <a class="cus-btn transparent text-16" onClick={() => { openModal(); }}>
                                            Enroll Now
                                        </a>
                                        <a class="cus-btn transparent text-16 px-2" onClick={() => {
                                            openModal();
                                        }}>
                                            <i aria-hidden="true" class="fas fa-cloud-download-alt"></i>
                                            Brochure
                                        </a>
                                    </div>
                                    <p class="black mt-2 mb-2">Enroll to access this course</p>
                                    <div class="hr-line mb-24"></div>
                                    <div>

                                    </div>
                                    <i class="far fa-user"></i><p class="black">All Levels</p>
                                    <i class="far fa-user"></i><p class="black">{Location?.state?.CoursesTotalStudentsEnrolled} Total Enrolled</p>
                                    <i class="far fa-user"></i> <p class="black ">{Location?.state?.CoursesDuration} Duration</p>
                                </div>
                                <div class="hr-line mb-24"></div>
                                <div class="filter-block box-4 mb-24">
                                    <div class="title mb-24" data-count="4">
                                        <h5 class="black">Tags</h5>
                                        <span>
                                            <i class="far fa-horizontal-rule"></i>
                                        </span>
                                    </div>
                                    <div class="content-block">
                                        <div class="blog-tags-wrapper">
                                            {Location?.state?.CourseTags && Location?.state?.CourseTags.length != 0 &&
                                                Location?.state?.CourseTags.map((x, index) => {
                                                    return (
                                                        <a class="blog-tags fw-300 lightest-gray">
                                                            {x?.TagName}
                                                        </a>
                                                    );
                                                })}
                                        </div>
                                    </div>
                                </div>
                                {/* <div class="hr-line mb-24"></div> */}
                                <div class="featured-product-sec">
                                    {/* <img src="assets/media/blog/blog-sidebar.png" alt="" class="w-100 br-10" /> */}
                                </div>
                                <div class="elementor-widget-container">
                                    <a
                                        href="https://www.facebook.com/"
                                        target="_blank" rel="noopener">
                                        <img loading="lazy" decoding="async" alt="Facebook" width="40" height="40" src="../assets/media/icons8-facebook.svg" class=" lazyloaded" />
                                        <noscript>
                                            <img loading="lazy" decoding="async" src="../assets/media/icons8-facebook.svg" alt="Facebook" width="40" height="40" />
                                        </noscript>
                                    </a>
                                    <a href="https://x.com/" target="_blank" rel="noopener">
                                        <img loading="lazy" decoding="async" alt="Twitter" width="40" height="40" src="../assets/media/icons8-twitter-6.svg" class=" lazyloaded" />
                                        <noscript>
                                            <img loading="lazy" decoding="async" src="../assets/media/icons8-twitter-6.svg" alt="Twitter" width="40" height="40" />
                                        </noscript>
                                    </a>
                                    <a href="https://www.linkedin.com" target="_blank" rel="noopener">
                                        <img loading="lazy" decoding="async" alt="LinkedIn" width="40" height="40" src="../assets/media/icons8-linkedin.svg" class=" lazyloaded" />
                                        <noscript>
                                            <img loading="lazy" decoding="async" src="../assets/media/icons8-linkedin.svg" alt="LinkedIn" width="40" height="40" />
                                        </noscript>
                                    </a>
                                    <a
                                        href="https://t.me/share/url?url=http://academy.rjtechlabs.com/" target="_blank" rel="noopener"
                                    >
                                        <img loading="lazy" decoding="async" alt="Telegram" width="40" height="40" src="../assets/media/icons8-telegram.svg" class=" lazyloaded" />
                                        <noscript>
                                            <img loading="lazy" decoding="async" src="../assets/media//icons8-telegram.svg" alt="Telegram" width="40" height="40" />
                                        </noscript>
                                    </a>
                                    <a
                                    //  href="https://api.whatsapp.com/send?text=https%3A%2F%2Fwww.nextitcareer.com%2Fbest-azure-devops-training-in-hyderabad%2F" target="_blank" rel="noopener"
                                    >
                                        <img loading="lazy" decoding="async" alt="WhatsApp" width="40" height="40" src="../assets/media/icons8-whatsapp.svg" class=" lazyloaded" />
                                        <noscript>
                                            <img loading="lazy" decoding="async" src="../assets/media/icons8-whatsapp.svg" alt="WhatsApp" width="40" height="40" />
                                        </noscript>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-5 ">
                        <h3 class="black font-primary fw-600 mb-16">Related Course</h3>

                        {Location?.state?.RelatedCourses && Location?.state?.RelatedCourses.length != 0 &&
                            Location?.state?.RelatedCourses.map((x, index) => {
                                return (
                                    <div class="col-xl-3 col-lg-6 col-sm-6 " key={index + 1}>
                                        <div class="special-item p-24" style={{ height: '100%', boxShadow: '0px 0px 10px 5px rgba(0, 0, 0, 0.2)', border: '1px solid #fff' }}>
                                            <div class="item-image">
                                                <a >
                                                    <img src={x?.CoursesImage} alt="" />
                                                </a>
                                            </div>
                                            <div class="item-detail">
                                                <div class="rating mb-16">
                                                    <i class="fa-solid fa-star-sharp"></i>
                                                    <i class="fa-solid fa-star-sharp"></i>
                                                    <i class="fa-solid fa-star-sharp"></i>
                                                    <i class="fa-solid fa-star-sharp"></i>
                                                    <i class="fa-solid fa-star-sharp"></i>
                                                </div>
                                                <a href="product-detail.html" class="h6 black color-white mb-8">
                                                    {x?.CoursesName}
                                                </a>
                                                <p class="text-16 light-gray mb-24">{x?.CoursesDescrption}</p>
                                                <div class="d-flex align-items-center justify-content-between">
                                                    {/* <span class="h6 color-sec">$20.00</span> */}
                                                    {/* <a class="cus-btn transparent textd-16 p-8-16"
                                                        onClick={() => {
                                                            navigate('/coursesDetails', { state: x })
                                                        }}>
                                                        Enroll Now
                                                        <i class="far fa-shopping-cart"></i>
                                                    </a> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
                <CommonModal isOpen={isModalOpen} onClose={closeModal}>
                    <EnrollNow closeModal={closeModal} />
                </CommonModal>
            </section>
        </>
    )
}

export default CoursesDetails