import React from 'react'

const Projects = () => {
    return (
        <section class="about py-80">
            <div class="container-fluid">
                <div class="row align-items-center row-gap-5">
                    <div className='col-md-12'>
                    <div class="about-detail">
                            <h2 class="color-white fw-600 font-primary mb-16">
                                <span class="black d-inline">React Js</span></h2>
                            <p class="text-16 black mb-12">
                                <strong>  Project Title: </strong> CourseHub – Online Course Management System
                            </p>
                            <p className='black'>The objective of the CourseHub project is to develop a responsive and user-friendly web application that enables users to manage and participate in online courses. The application will cater to both students and instructors, offering features such as course enrollment, progress tracking, and content management</p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        
                        <div>
                            <h4>Project Overview</h4>
                            <ul className='black'>
                                <li>
                                    <strong>Project Objective:</strong>
                                    Define the main goals of the project, including the problem it aims to solve and the value it will bring to users or stakeholders.
                                </li>
                                <li>
                                    <strong>Technology Stack:</strong>
                                    Outline the technologies that will be used in the project, such as React.js for the front-end, Node.js for the back-end, and any other libraries, databases, or tools involved.
                                </li>
                                <li>
                                    <strong>Design and User Experience:</strong>
                                    Describe the design approach, including the user interface (UI) layout, color schemes, and how the project will ensure a smooth and intuitive user experience (UX).
                                </li>
                                <li>
                                    <strong>Development Phases:</strong>
                                    Break down the project into different phases, such as planning, development, testing, and deployment. Specify the deliverables for each phase.
                                </li>
                                <li>
                                    <strong>Challenges and Solutions:</strong>
                                    Discuss potential challenges that may arise during the project and outline strategies for addressing them, such as code modularity, performance optimization, and scalability.
                                </li>
                                <li>
                                    <strong>Testing and Quality Assurance:</strong>
                                    Explain the testing strategy for the project, including unit testing, integration testing, and user acceptance testing to ensure the project meets all requirements.
                                </li>
                                <li>
                                    <strong>Deployment and Maintenance:</strong>
                                    Describe the deployment process, including hosting options and the steps for launching the project to a live environment. Also, mention plans for ongoing maintenance and updates.
                                </li>
                                <li>
                                    <strong>Documentation:</strong>
                                    Highlight the importance of thorough documentation, including code comments, project documentation, and user manuals, to ensure the project is maintainable and understandable for future developers.
                                </li>
                                <li>
                                    <strong>Project Timeline:</strong>
                                    Provide a timeline for the project, detailing key milestones and deadlines to keep the project on track and ensure timely delivery.
                                </li>
                            </ul>
                        </div>

                        <a class=" cus-btn primary texat-16 mt-lg-4" data-bs-toggle="modal" data-bs-target="#Internships">
                            Apply Now
                        </a>
                    </div>
                    <div class="col-lg-6">
                        <div class="about-image">
                            <img src="assets/media/about/react3.jpg" alt="" style={{ height: '90%', width: "80%" }} />
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="Internships" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="InternshipsLabel" aria-hidden="true" >
                <div class="modal-dialog rounded" style={{ backgroundColor: 'white' }}>
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="InternshipsLabel">Registration</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <form>
                                <div className='row'>
                                    <div class="mb-3 col-md-4">
                                        <label for="exampleInputEmail1" class="form-label">Email address</label> <br />
                                        <input type="email" class=" normal" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                    </div>
                                    <div class="mb-3 col-md-4">
                                        <label for="exampleInputEmail1" class="form-label">Name</label> <br />
                                        <input type="text" class=" normal" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                    </div>
                                    <div class="mb-3 col-md-4">
                                        <label for="exampleInputEmail1" class="form-label">Phone Number</label> <br />
                                        <input type="number" class=" normal" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                    </div>
                                    <div class="mb-3 col-md-4">
                                        <label for="exampleInputPassword1" class="form-label">Qualification</label> <br />
                                        <input type="text" class="normal" id="exampleInputPassword1" />
                                    </div>
                                    <div class="mb-3 col-md-4">
                                        <label for="exampleInputPassword1" class="form-label">Internship Name</label> <br />
                                        <input type="text" class="normal" id="exampleInputPassword1" />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Projects