import React from 'react'
import Modal from 'react-modal';

const customStyles = {
    content: {
        top: '80%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '50%',
        marginTop:'50px',
        transform: 'translate(50%, -10%)',
        with:'50%',
    },
};
const CommonModal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;
    return (
        <Modal
            isOpen={isOpen}
            // onAfterOpen={afterOpenModal}
            onRequestClose={onClose}
            style={customStyles}
            contentLabel="Example Modal"
            id="customModal"
            className="common-modal"
        >
            {children}

        </Modal>
    )
}

export default CommonModal