import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { RJTECHLABS_URLS } from '../../utills/url';
import { toast } from 'react-toastify';

const EnrollNow = ({ closeModal }) => {
	const [allCourses, setAllCourses] = useState([])
    const [payload, setPayload] = useState({
        EnrollStudentName: '',
        EnrollEmailID: '',
        EnrollMobileNo: '',
        CoursesMasterID: '',
        Location: ''
    });
    const [isLocation, setIslocation] = useState('');

    useEffect(() => {
        getLocation();
		getAllCourses();
    }, [])


    const getLocation = () => {
    	axios.get('https://ipapi.co/json/').then((res) => {
    		setIslocation(res.data);
    	});
    }

	const getAllCourses = async () => {
		await axios.get(RJTECHLABS_URLS.getAllCoursesApprovalDetails + 1,
			{
				headers:
					{ 'apikey': RJTECHLABS_URLS.API_KEY }
			})
			.then((res) => {
				if (res.data.status == 200) {
					console.log("res.data", res?.data?.data);
					setAllCourses(res?.data?.data);
				}
				else {
				}
			})
			.catch(function (e) {
			})
	}

    const addEnrollStudentData = () => {
        let enrollPayload = {
            EnrollStudentName: payload.EnrollStudentName,
            EnrollEmailID: payload.EnrollEmailID,
            EnrollMobileNo: payload.EnrollMobileNo,
            CoursesMasterID: payload.CoursesMasterID ? Number(payload.CoursesMasterID) : '',
            Location: isLocation ? `${isLocation.city}, ${isLocation.region}, ${isLocation.region_code}` : ''

        }
        console.log("payload", enrollPayload);
        axios.post(RJTECHLABS_URLS.addEnrollStudentData, enrollPayload,
            { headers: { 'apikey': RJTECHLABS_URLS.API_KEY } })
            .then((response) => {
                if (response.data.status == 200) {
                    console.log("Percentage", response.data);
                    toast.success('Enroll successfully...!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setPayload({
                        EnrollStudentName: '',
                        EnrollEmailID: '',
                        EnrollMobileNo: '',
                        CoursesMasterID: '',
                        Location: ''
                    });
                    closeModal();
                } else {
                    toast.warning(response.data.message);
                }
            })
            .catch(function (e) {
                toast.error('Please Try Again');
                console.log(e)
            });
    }

    return (
        <div className="row align-items-center">
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header">
                        <div className="d-flex  align-items-center">
                            <h5 className="flex-grow-1  oxt">Enroll Now</h5>
                            <div className="flex-column pointer">
                                <i class="fa-solid fa-xmark" width={20} onClick={closeModal}></i>
                                {/* <img src='/assets/img/close.png'  /> */}
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="card-body">
                        <div class="row mt-5">
                            <div class="col-md-6">
                                <div class="input-block mb-24">
                                {/* <label htmlFor="emailAddress" className="form-label">
                                    Name
                                </label> */}
                                    <input type="text" name="yname" id="yourName" class="form-control" placeholder="Your Name"
                                        onChange={(e) => {
                                            let p = payload
                                            p.EnrollStudentName = e.target.value
                                            setPayload({ ...p })
                                        }}
                                    />
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path
                                            d="M10 0C7.09223 0 4.72656 2.36566 4.72656 5.27344C4.72656 8.18121 7.09223 10.5469 10 10.5469C12.9078 10.5469 15.2734 8.18121 15.2734 5.27344C15.2734 2.36566 12.9078 0 10 0ZM10 9.375C7.7384 9.375 5.89844 7.53504 5.89844 5.27344C5.89844 3.01184 7.7384 1.17188 10 1.17188C12.2616 1.17188 14.1016 3.01184 14.1016 5.27344C14.1016 7.53504 12.2616 9.375 10 9.375Z"
                                            fill="#000" />
                                        <path
                                            d="M16.5612 13.992C15.1174 12.5261 13.2035 11.7188 11.1719 11.7188H8.82812C6.79656 11.7188 4.88258 12.5261 3.43883 13.992C2.00215 15.4507 1.21094 17.3763 1.21094 19.4141C1.21094 19.7377 1.47328 20 1.79688 20H18.2031C18.5267 20 18.7891 19.7377 18.7891 19.4141C18.7891 17.3763 17.9979 15.4507 16.5612 13.992ZM2.40859 18.8281C2.70215 15.5045 5.46918 12.8906 8.82812 12.8906H11.1719C14.5308 12.8906 17.2979 15.5045 17.5914 18.8281H2.40859Z"
                                            fill="#000" />
                                    </svg>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-block mb-24">
                                    <input type="tel" name="phone" id="number" maxLength={13} class="form-control" placeholder="Phone Number"
                                        onChange={(e) => {
                                            let p = payload
                                            p.EnrollMobileNo = e.target.value
                                            setPayload({ ...p })
                                        }}
                                    />
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path
                                            d="M16.2509 15.5304L15.8338 14.8339C15.0836 13.5971 14.0484 12.1776 12.6904 12.1776C12.4389 12.1776 12.1897 12.2282 11.9431 12.3307L11.2142 12.6432C11.1477 12.6708 11.083 12.7022 11.0145 12.7355C10.828 12.8262 10.6165 12.929 10.3989 12.929C9.86217 12.929 9.2403 12.2305 8.64811 10.9623C8.06692 9.71762 8.10399 9.06508 8.23741 8.73673C8.38462 8.37447 8.72695 8.21959 9.09471 8.08041C9.14585 8.06101 9.19204 8.04342 9.23696 8.02493L9.97501 7.71418C11.8978 6.9101 11.1825 4.10014 10.948 3.17889L10.7491 2.3868C10.579 1.734 10.1282 0 8.63277 0C8.35594 0 8.06061 0.064496 7.75527 0.191774C7.55492 0.271334 4.79792 1.39672 3.79909 3.37382C2.60532 5.72716 2.82605 8.88296 4.45451 12.7515C6.07079 16.625 8.16669 18.9942 10.684 19.7933C11.1158 19.9305 11.6038 19.9999 12.1346 19.9999H12.1349C13.8721 19.9999 15.587 19.2608 15.7262 19.1994C16.3251 18.9457 16.7123 18.5601 16.8768 18.0532C17.1557 17.1937 16.6878 16.2519 16.2509 15.5304ZM15.5897 17.6356C15.5514 17.7534 15.4185 17.8609 15.1948 17.9551C15.1911 17.9567 15.1866 17.9586 15.1828 17.9603C15.1673 17.9672 13.615 18.647 12.1345 18.6469C11.7428 18.6469 11.3926 18.5988 11.0935 18.5037C8.97266 17.8305 7.1592 15.72 5.70231 12.2286C4.23459 8.7416 4.00006 5.96837 5.0062 3.98495C5.78746 2.43858 8.23335 1.45761 8.25734 1.44823C8.26221 1.44625 8.26699 1.44435 8.27177 1.44237C8.41105 1.38391 8.53589 1.35306 8.63277 1.35306C8.9309 1.35306 9.20223 1.81545 9.43803 2.72173L9.63602 3.51048C10.0632 5.18828 9.99819 6.23772 9.45129 6.46647L8.71675 6.77587C8.68753 6.78796 8.65334 6.80068 8.61563 6.81502C8.20989 6.96864 7.36558 7.28814 6.98375 8.22735C6.63727 9.0796 6.7807 10.1615 7.42178 11.535C8.28521 13.3835 9.25897 14.2821 10.3987 14.2821C10.9277 14.2821 11.3523 14.0757 11.6059 13.9525C11.6526 13.9298 11.6944 13.909 11.7393 13.8904L12.4693 13.5774C12.5446 13.546 12.6169 13.5307 12.6903 13.5307C13.0417 13.5307 13.6717 13.8786 14.6749 15.5325L15.0918 16.2285C15.6055 17.0767 15.6461 17.4618 15.5897 17.6356Z"
                                            fill="#000" />
                                    </svg>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-block mb-24">
                                    <select class=" form-control"
                                        onChange={(e) => {
                                            let p = payload
                                            p.CoursesMasterID = e.target.value
                                            setPayload({ ...p })
                                        }}>
                                        <option selected>Select Course</option>
                                        {allCourses && allCourses.map((option) => {
                                            return <option key={option.CourseID} value={option.CourseID}>
                                                {option.CoursesName}
                                            </option>
                                        })}
                                    </select>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path
                                            d="M19.7337 4.81165C19.3788 4.45668 18.8031 4.45662 18.4481 4.81171L10.0002 13.2598L1.55191 4.81165C1.19694 4.45668 0.621303 4.45662 0.266273 4.81171C-0.0887576 5.16674 -0.0887576 5.74232 0.266273 6.09735L9.35742 15.1883C9.52791 15.3587 9.75912 15.4545 10.0002 15.4545C10.2413 15.4545 10.4726 15.3587 10.643 15.1882L19.7337 6.09729C20.0888 5.74232 20.0888 5.16668 19.7337 4.81165Z"
                                            fill="#000000" />
                                    </svg>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-block mb-24">
                                    <input type="email" name="mail" id="mailing" class="form-control" placeholder="Email"
                                        onChange={(e) => {
                                            let p = payload
                                            p.EnrollEmailID = e.target.value
                                            setPayload({ ...p })
                                        }} />
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path
                                            d="M18.2422 2.96875H1.75781C0.786602 2.96875 0 3.76023 0 4.72656V15.2734C0 16.2455 0.792383 17.0312 1.75781 17.0312H18.2422C19.2053 17.0312 20 16.2488 20 15.2734V4.72656C20 3.76195 19.2165 2.96875 18.2422 2.96875ZM17.996 4.14062C17.6369 4.49785 11.4564 10.6458 11.243 10.8581C10.9109 11.1901 10.4695 11.3729 10 11.3729C9.53047 11.3729 9.08906 11.1901 8.75594 10.857C8.61242 10.7142 2.50012 4.63414 2.00398 4.14062H17.996ZM1.17188 15.0349V4.96582L6.23586 10.0031L1.17188 15.0349ZM2.00473 15.8594L7.06672 10.8296L7.9284 11.6867C8.48176 12.2401 9.21746 12.5448 10 12.5448C10.7825 12.5448 11.5182 12.2401 12.0705 11.6878L12.9333 10.8296L17.9953 15.8594H2.00473ZM18.8281 15.0349L13.7641 10.0031L18.8281 4.96582V15.0349Z"
                                            fill="#000" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="card-footer bg-transparent border-0">
                        <div className="row mb-3 d-flex justify-content-end">
                            <div className="col-md-4">
                                <button type="submit"
                                    className="btn btn-outline-secondary rounded-pill fw-bold w-100 oxt fw-bold"
                                    onClick={closeModal}>
                                    Cancel
                                </button>
                            </div>
                            <div className="col-md-4">
                                <button type="submit"
                                    className="btn btn-primary rounded-pill fw-bold w-100 oxt fw-bold"
                                    onClick={() => {addEnrollStudentData();}}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EnrollNow