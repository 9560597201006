import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { RJTECHLABS_URLS } from '../../utills/url';

const SampleCourses = () => {
    const navigate = useNavigate();

    const [coursesList, setSoursesList] = useState([]);

    useEffect(() => {
        getWeAreSpecializedDetails();
    }, []);



    const getWeAreSpecializedDetails = async () => {
        // setLoading(true)
        await axios.get(RJTECHLABS_URLS.getDashBoardCourses,
            {
                headers:
                    { 'apikey': RJTECHLABS_URLS.API_KEY }
            })
            .then((res) => {
                if (res.data.status === 200) {
                    console.log("res.data", res.data.data);
                    setSoursesList(res.data.data);
                }
                else {
                }
            })
            .catch(function (e) {
            })
    }

    return (
        <>
            <section class="special py-80">
                <div class="container-fluid">
                    <div class="d-flex flex-column align-items-center gap-8 text-center mb-48">
                        <h5 class="text-md dark-gray text-uppercase">OUR COURSES</h5>
                        <h2 class="black font-primary fw-600">Our Advanced Language Courses</h2>
                        <p class="light-gray">Learn some of the most current and trending courses with 100% placement assistance from <br />
                            Best software training institute in Hyderabad RJ Tech Labs </p>
                    </div>
                    <div class="row row-gap-4">
                        {coursesList && coursesList.length > 0 &&
                            coursesList.map((x, index) => {
                                return (
                                    <div class="col-xl-3 col-lg-6 col-sm-6 " key={index + 1}>
                                        <div class="special-item p-24" style={{ height: '100%', boxShadow: '0px 0px 10px 5px rgba(0, 0, 0, 0.2)', border: '1px solid #fff' }}>
                                            <div class="item-image">
                                                <a >
                                                    <img src={x?.CoursesImage} alt="" />
                                                </a>
                                            </div>
                                            <div class="item-detail">
                                                <div class="rating mb-16">
                                                    <i class="fa-solid fa-star-sharp"></i>
                                                    <i class="fa-solid fa-star-sharp"></i>
                                                    <i class="fa-solid fa-star-sharp"></i>
                                                    <i class="fa-solid fa-star-sharp"></i>
                                                    <i class="fa-solid fa-star-sharp"></i>
                                                </div>
                                                <a href="product-detail.html" class="h6 black color-white mb-8">
                                                    {x?.CoursesName}
                                                </a>
                                                <p class="text-16 light-gray mb-24">{x?.CoursesDescrption}</p>
                                                <div class="d-flex align-items-center justify-content-between">
                                                    <a class="cus-btn transparent textd-16 p-8-16"
                                                        onClick={() => {
                                                            navigate('/coursesDetails', { state: x })
                                                        }}>
                                                        Enroll Now
                                                        <i class="far fa-shopping-cart"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                       }
                    </div>
                </div>
            </section>
        </>
    )
}

export default SampleCourses