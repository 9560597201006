import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { RJTECHLABS_URLS } from '../../utills/url';

const AboutUsHome = () => {
    const [about, setAbout] = useState([]);

    useEffect(() => {
        getAboutUsDetails();
    }, []);



    const getAboutUsDetails = async () => {
        // setLoading(true)
        await axios.get(RJTECHLABS_URLS.getAboutUsDetails,
            {
                headers:
                    { 'apikey': RJTECHLABS_URLS.API_KEY }
            })
            .then((res) => {
                if (res.data.status === 200) {
                    console.log("res.data", res.data.data);
                    setAbout(res.data.data);
                }
                else {
                }
            })
            .catch(function (e) {
            })
    }
    return (
        <section class="about py-80">
            <div class="container-fluid">
            {about && about.length > 0 && about.slice(0, 1).map((items, index) => {
                        return <div class="row align-items-center row-gap-5" key={index + 1}>
                            <div class="col-lg-6">
                                <div class="about-detail">
                                    <p class="section-name text-md text-uppercase black mb-8">About us</p>
                                    <h2 class="color-white fw-600 font-primary mb-16">
                                        <span class="black d-inline">{items?.AboutUsHeading}</span></h2>
                                    <p class="text-16 black mb-12">{items?.AboutUsDesc}</p>
                                    <a class=" cus-btn primary texat-16 mt-lg-4">
                                        READ MORE
                                    </a>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="about-image">
                                    <img src={items?.AboutUsImage ?? "assets/media/about/team.jpg"} alt="" />
                                </div>
                            </div>
                        </div>
                    })}
            </div>
        </section>
    )
}

export default AboutUsHome