import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CommonModal from '../../components/commonModal'
import EnrollNow from '../../components/EnrollNow'

const HeroBanner = () => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    return (
        <section class="hero-banner">
            <div class="hero-slider">
                <div class="slider-block slide-1">
                    <div class="detail">
                        {/* <div class="mb-48">
                            <h2 class="h1 fw-400 color-white mb-16">Best software training institute in Hyderabad</h2>
                            <p class="text-16 lightest-gray">Encapsulates our dedication to fostering outstanding achievements and nurturing innate abilities.</p>
                        </div> */}
                        {/* <div class="d-flex align-items-center justify-content-center gap-24">
                            <a class="cus-btn primary text-16">
                                Enroll Now
                            </a>
                            <a class="cus-btn transparent text-16">
                                CONTACT US
                            </a>
                        </div> */}
                    </div>
                </div>
                <div class="slider-block slide-2">
                    <div class="detail">
                        <div class="mb-48">
                            <h2 class="h1 fw-400 color-white mb-16">Building Skills, Shaping Careers</h2>
                            <p class="text-16 lightest-gray">Reflects our commitment to equipping individuals with essential tools and expertise. Through tailored training and hands-on learning, we empower professionals to shape successful, dynamic careers.</p>
                        </div>
                        <div class="d-flex align-items-center justify-content-center gap-24">
                            <a class="cus-btn primary text-16" onClick={()=>{openModal()}}>
                                Enroll Now
                            </a>
                            <a class="cus-btn transparent text-16" onClick={() => navigate('/contact')}>
                                CONTACT US
                            </a>
                        </div>
                    </div>
                </div>
                <div class="slider-block slide-3">
                    <div class="detail">
                        <div class="mb-48">
                            <h2 class="h1 fw-400 color-white mb-16">Inspiring Excellence, Nurturing Talent</h2>
                            <p class="text-16 lightest-gray">Explore opportunities, acquire knowledge, achieve greatness.
                                Building Skills, Shaping Careers
                            </p>
                        </div>
                        <div class="d-flex align-items-center justify-content-center gap-24">
                            <a class="cus-btn primary text-16" onClick={()=>{openModal()}}>
                                Enroll Now
                            </a>
                            <a class="cus-btn transparent text-16" onClick={() => navigate('/contact')}>
                                CONTACT US
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <CommonModal isOpen={isModalOpen} onClose={closeModal}>
                <EnrollNow closeModal={closeModal} />
            </CommonModal>
        </section>
    )
}

export default HeroBanner