import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { RJTECHLABS_URLS } from '../../utills/url';

const Team = () => {

    const [about, setAbout] = useState([]);

    useEffect(() => {
        getWeAreSpecializedDetails();
    }, []);



    const getWeAreSpecializedDetails = async () => {
        // setLoading(true)
        await axios.get(RJTECHLABS_URLS.getWeAreSpecializedDetails,
            {
                headers:
                    { 'apikey': RJTECHLABS_URLS.API_KEY }
            })
            .then((res) => {
                if (res.data.status === 200) {
                    console.log("res.data", res.data.data);
                    setAbout(res.data.data);
                }
                else {
                }
            })
            .catch(function (e) {
            })
    }
    return (
        <section class="benefits py-40">
            <div class="container-fluid">
                <div class="row row-gap-3">
                    <h2 class="black fw-600 font-primary text-center ">We are Specialized
                        <br />
                    </h2>
                    {about && about.length > 0 && about.map((items, index) => {
                   return     <div class="col-lg-3 col-6" key={index+1}>
                            <div class="benefit-block">
                                <img src={items?.WeAreSpecializedImage ?? "assets/media/icon/scooter.png"} alt="" />
                                <h4 class="black">{items?.WeAreSpecializedTitleName}</h4>
                                <p class="text-16 black " style={{ textAlign: 'left' }}>{items?.WeAreSpecializedDesc}</p>
                            </div>
                        </div>
                    })}
                    {/* <div class="col-lg-3 col-6">
                        <div class="benefit-block">
                            <img src="assets/media/icon/online.png" alt="" />
                            <h4 class="black">Online Trainings</h4>
                            <p class="text-16 black" style={{ textAlign: 'left' }}>Take your career to the next level with our  online course. Set your own schedule to balance your work and personal commitments and complete coursework at your own pace.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-6">
                        <div class="benefit-block">
                            <img src="assets/media/icon/gift-box.png" alt="" />
                            <h4 class="black">Internships</h4>
                            <p class="text-16 black" style={{ textAlign: 'left' }}>Our Internship Programme offers students and recent graduates from diverse academic backgrounds the opportunity to gain hands-on experience through global internship opportunities with RJtechlabs.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-6">
                        <div class="benefit-block">
                            <img src="assets/media/icon/gift-box.png" alt="" />
                            <h4 class="black">Corporate training </h4>
                            <p class="text-16 black" style={{ textAlign: 'left' }}>Give your team the skills they need to have impact right away while learning together with our esteemed courses. Master on-demand skills under our guidance with our industry experts.</p>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
    )
}

export default Team