import React, { useState, useEffect, useContext } from 'react';
import { Route, Routes, useParams } from "react-router-dom";
import Contact from '../../components/Contact';
import AboutUs from '../aboutUs';
import Blogs from '../blogs';
import ComingSoon from '../comingSoon';
import Courses from '../courses';
import CoursesDetails from '../coursesDetails';
import Home from '../home';
import Internships from '../Internships';
import Projects from '../Projects';
import WorkShops from '../workShop';

const Layout=()=> {
  return (
    <>
      <section >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/coursesDetails" element={<CoursesDetails />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/comingSoon" element={<ComingSoon />} />
          <Route path="/WorkShops" element={<WorkShops />} />
          <Route path="/Internships" element={<Internships />} />
          <Route path="/Projects" element={<Projects />} />
          {/* <Route path="/blogs" element={<Blogs />} /> */}
        </Routes>
        
      </section>
    </>
  )
}

export default Layout