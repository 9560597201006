import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { RJTECHLABS_URLS } from '../../utills/url'

const Footer = () => {
  const [allCourses, setAllCourses] = useState([])

  useEffect(() => {
    getAllCourses()
  }, [])
  const getAllCourses = async () => {
    await axios.get(RJTECHLABS_URLS.getAllCoursesApprovalDetails + 1,
      {
        headers:
          { 'apikey': RJTECHLABS_URLS.API_KEY }
      })
      .then((res) => {
        if (res.data.status == 200) {
          console.log("res.data", res?.data?.data);
          setAllCourses(res?.data?.data);
        }
        else {
        }
      })
      .catch(function (e) {
      })
  }
  return (
    <>
      <footer class="footer-wrapper footer-layout3 bg-footer">
        <div class="widget-area ">
          <div class="container">
            <div class="row justify-content-between">
              <div class="col-lg-3 col-md-3 col-12">
                <div class="widget footer-widget">
                  <h3 class="widget_title">Contact Us</h3>
                  <div class="vs-widget-about">
                    <p class="footer-info">
                      <small class="text-light-white">General Enquiries:</small><br />
                      <a href="mailto:info@rjtechlabs.com">info@rjtechlabs.com</a>
                    </p>
                    <p class="footer-info">
                      <small class="text-light-white">Marketing:</small><br />
                      <a href="mailto:info@rjtechlabs.com">marketing@rjtechlabs.com</a>
                    </p>
                    <p class="footer-info">
                      <small class="text-light-white">Address:</small><br />
                      <p>Unit.No. 114, Manjeera Trinity Corporate, JNTU - Hitech Road, beside LuLu Mall, Kukatpally Housing Board Colony, Kukatpally, Hyderabad, Telangana 500072</p>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-6">
                <div class="widget widget_categories  footer-widget">
                  <h3 class="widget_title">Our Courses</h3>
                  <ul>
                    {allCourses && allCourses.length != 0 &&
                      allCourses.map((x, index) => {
                        return <li class="mb-12 " key={index + 1}><a href="#">{x?.CoursesName}</a></li>
                      })}
                    {/* <li class="mb-12 "><a href="#">Azure Training</a></li>
                    <li class="mb-12 "><a href="#">Devops Training</a></li>
                    <li class="mb-12 "><a href="#">UI path Training</a></li>
                    <li class="mb-12 "><a href="#">Cloud Testing</a></li>
                    <li class="mb-12 "><a href="#">Data Analytics</a></li> */}
                  </ul>
                </div>
              </div>
              {/* <div class="col-lg-3 col-md-3 col-6">
                <div class="widget widget_nav_menu  footer-widget">
                  <h3 class="widget_title">Quick Links</h3>
                  <div class="menu-all-pages-container">
                    <ul class="menu">
                      <li class="mb-12 "><a href="#">AWS Training</a></li>
                      <li class="mb-12 "><a href="#">Azure Training</a></li>
                      <li class="mb-12 "><a href="#">Devops Training</a></li>
                      <li class="mb-12 "><a href="#">UI path Training</a></li>
                      <li class="mb-12 "><a href="#">Cloud Testing</a></li>
                      <li class="mb-12 "><a href="#">Data Analytics</a></li>
                    </ul>
                  </div>
                </div>
              </div> */}
              <div class="col-lg-6 col-md-12 col-12">
                <div class="widget footer-widget">
                  <div class="vs-widget-about">
                    <h3 class="widget_title">Join Our Community</h3>
                    <div class="multi-social">
                      <div class="row">
                        <div class="col-lg-12 ">
                          <a href="#" target="_blank"><img
                            src="assets/media/social/telegram.png" /></a>
                          <a href="#" target="_blank"><img
                            src="assets/media/social/twitter.png" /></a>
                          <a href="#"
                            target="_blank"><img src="assets/media/social/youtube.png" /></a>
                        </div>
                        <div class="col-lg-12 ">
                          <a href="#"
                            target="_blank"><img src="assets/media/social/linkedin.png" /></a>
                          <a href="#" target="_blank"><img
                            src="assets/media/social/instagram.png" /></a>
                          <a href="#" target="_blank"
                            class="opacity"><img src="assets/media/social/facebook.png" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <p class="text-center sec-text text-white">&copy; Copyright 2024 <b className='title'>RJ Tech Labs</b>. All rights
              reserved</p>
          </div>
        </div>
      </footer>
      <a
          href="https://wa.me/+919618366575?text=Hello%20there!"
          target="_blank"
        >
          <img src="../assets/media/icons8-whatsapp.svg" alt="WhatsApp" className="whatsapp" />
        </a>
    </>
  )
}

export default Footer