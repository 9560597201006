import React from 'react'

const WorkShops = () => {
    return (
        <div>
            <section class="about py-80">
                <div class="container-fluid">
                    <div class="row align-items-center row-gap-5">
                        <div class="col-lg-6">
                            <div class="about-image">
                                <img src="assets/media/about/reacttwo.jpg" alt="" style={{ height: '90%', width: "80%" }} />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="about-detail">
                                <h2 class="color-white fw-600 font-primary mb-16">
                                    <span class="black d-inline">React Js</span></h2>
                                <p class="text-16 black mb-12">
                                React has been designed from the start for gradual adoption, and you can use as little or as much React as you need.
                                </p>

                            </div>
                            <div>
                                <h4>Workshop Overview</h4>
                                <ul className='black'>
                                    <li>
                                        <strong>Introduction to React.js:</strong>
                                        Gain a fundamental understanding of React.js, including its core concepts, history, and the problems it solves in modern web development.
                                    </li>
                                    <li>
                                        <strong>Setting Up the Development Environment:</strong>
                                        Learn how to set up a React.js development environment using tools like Node.js, npm, and popular code editors such as Visual Studio Code.
                                    </li>
                                    <li>
                                        <strong>Building Your First Component:</strong>
                                        Create your first React component, understand the concept of JSX, and learn how to render components to the DOM.
                                    </li>
                                    <li>
                                        <strong>State and Props:</strong>
                                        Explore the concepts of state and props in React, and understand how data flows through a React application.
                                    </li>
                                    <li>
                                        <strong>Handling Events:</strong>
                                        Learn how to handle user interactions and events in React components to make your applications dynamic and interactive.
                                    </li>
                                    <li>
                                        <strong>React Hooks:</strong>
                                        Discover the power of React hooks like <code>useState</code>, <code>useEffect</code>, and more to manage state and side effects in functional components.
                                    </li>
                                    <li>
                                        <strong>Routing with React Router:</strong>
                                        Implement routing in your React application using React Router, enabling navigation between different pages and views.
                                    </li>
                                    <li>
                                        <strong>Styling React Applications:</strong>
                                        Learn how to style your React components using CSS, Styled Components, and other styling frameworks to create visually appealing UIs.
                                    </li>
                                    <li>
                                        <strong>Project Work:</strong>
                                        Apply what you've learned by working on a hands-on project, building a complete React.js application from scratch.
                                    </li>
                                    <li>
                                        <strong>Q&A Session:</strong>
                                        Wrap up the workshop with a Q&A session, where you can ask questions and get personalized guidance on your React.js journey.
                                    </li>
                                </ul>
                            </div>

                            <a class=" cus-btn primary texat-16 mt-lg-4" data-bs-toggle="modal" data-bs-target="#Internships">
                                Apply Now
                            </a>
                        </div>

                    </div>
                </div>
                <div class="modal fade" id="Internships" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="InternshipsLabel" aria-hidden="true" >
                    <div class="modal-dialog rounded" style={{ backgroundColor: 'white' }}>
                        <div class="modal-content">
                            <div class="modal-header">
                                <h1 class="modal-title fs-5" id="InternshipsLabel">Registration</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <form>
                                    <div className='row'>
                                        <div class="mb-3 col-md-4">
                                            <label for="exampleInputEmail1" class="form-label">Email address</label> <br />
                                            <input type="email" class=" normal" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                        </div>
                                        <div class="mb-3 col-md-4">
                                            <label for="exampleInputEmail1" class="form-label">Name</label> <br />
                                            <input type="text" class=" normal" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                        </div>
                                        <div class="mb-3 col-md-4">
                                            <label for="exampleInputEmail1" class="form-label">Phone Number</label> <br />
                                            <input type="number" class=" normal" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                        </div>
                                        <div class="mb-3 col-md-4">
                                            <label for="exampleInputPassword1" class="form-label">Qualification</label> <br />
                                            <input type="text" class="normal" id="exampleInputPassword1" />
                                        </div>
                                        <div class="mb-3 col-md-4">
                                            <label for="exampleInputPassword1" class="form-label">Internship Name</label> <br />
                                            <input type="text" class="normal" id="exampleInputPassword1" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default WorkShops