import React, { useEffect, useState } from "react";
import Header from "./components/header";
import Footer from './components/footer'
import Layout from './pages/layout'
import { ToastContainer } from "react-toastify";
import '../src/App.css'
import ScrollToTop from "./components/ScrollToTop";

function App() {
  // console.log = console.warn = console.error = () => { };

  // useEffect(() => { 
  //   axios.get('https://ipapi.co/json/').then((res) => { 
  //         setIpDetails(res.data); 
  //         setLat(res.data.latitude); 
  //         setLon(res.data.longitude); 
  //     }); 
  // }, []) 
  const [location, setLocation] = useState(null);
  const [address, setAddress] = useState(null);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);

    function successCallback(position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      setLocation({ latitude, longitude });

      // Fetch address from Google Geocoding API
      fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=704980075719-jttggkir7itivt4852a3p9e111cj0f0j.apps.googleusercontent.com`)
        .then(response => response.json())
        .then(data => {
          if (data.results.length > 0) {
            setAddress(data.results[0].formatted_address);
          }
        })
        .catch(error => console.error("Error fetching address:", error));
    }

    function errorCallback(error) {
      console.error("Error getting location:", error);
    }
  }, []);


  console.log("address", address);
  return (
    <div id="scroll-container">
      <header>
        <Header />
      </header>
      <div id="layout-footer-container">
        <Layout />
        <Footer />
        <ScrollToTop />
      </div>
    </div>

  );
}

export default App;
