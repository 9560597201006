import React from 'react'

const ComingSoon = () => {
  return (
   <>

<section class="coming-soon-page">
            <div class="container">
                <div class="row">
                    <div class="cs-block">
                        <div class="title-area text-center ">
                            <a href="/"><p className='black h1 fw-500'>RJ Tech Labs</p></a>
                        </div>
                        <h6 class="black mb-32 text-center">We are Under Construction</h6>
                        <div class="coming-soon-wrapper mb-32">
                            <ul class="unstyled countdown">
                                <li>
                                    <h2 class="h1">7</h2>
                                    <h6 class="fw-500 black">Days</h6>
                                </li>
                                <li>
                                    <h2 class="h1">24</h2>
                                    <h6 class="fw-500 black">Hrs</h6>
                                </li>
                                <li>
                                    <h2 class="h1">60</h2>
                                    <h6 class="fw-500 black">Min</h6>
                                </li>
                                <li>
                                    <h2 class="h1">60</h2>
                                    <h6 class=" black">Secs</h6>
                                </li>
                            </ul>
                        </div>
                        <div class="newsletter">
                            <h3 class="black font-primary coming-soon-text mb-32">Coming Soon</h3>
                            <div class="newsletter-heading justify-content-center mb-24">
                               
                                <div class="text text-center">
                                    <h3 class="black mb-8">Subscribe to Our Newsletter</h3>
                                    <p class="light-gray">Get notified when we are live</p>
                                </div>
                            </div>
                            <form >
                                <div class="d-flex align-items-center flex-sm-nowrap flex-wrap gap-12 mb-16">
                                    <input type="email" id="subscribe-emial" class="form-control" name="subscribe-email"
                                        placeholder="Your Email" style={{backgroundColor:'black'}}/>
                                    <button  class="cus-btn primary">
                                        Subscribe
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
   </>
  )
}

export default ComingSoon