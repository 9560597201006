// const baseUrl = "https://cryptobotsai-admin-api.m-blocks.net/dev/v1/"
// const baseUrl = "http://localhost:7800/dev/v1/"
const baseUrl = "https://rj-backend.onrender.com/dev/v1/"

export const RJTECHLABS_URLS = {
  addEnrollStudentData: baseUrl + "addEnrollStudentData",
  getWeAreSpecializedDetails: baseUrl + "getWeAreSpecializedDetails",
  getGreatFeatures: baseUrl + "getGreatFeatures",
  getAboutUsDetails: baseUrl + "getAboutUsDetails",
  getAllCoursesApprovalDetails: baseUrl + "getAllCoursesApprovalDetails/",
  getDashBoardCourses: baseUrl + "getDashBoardCourses",
  getPlacedStudentsData: baseUrl + "getPlacedStudentsData",

  API_KEY: "xCNpcsufSi5yYvGVCncEx0HAw1LonW1JoFU",
};
