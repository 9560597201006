import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { RJTECHLABS_URLS } from '../../utills/url';


const OurStudents = () => {
    
    const [studentsList, setStudentsList] = useState([]);

    useEffect(() => {
        getWeAreSpecializedDetails();
    }, []);



    const getWeAreSpecializedDetails = async () => {
        // setLoading(true)
        await axios.get(RJTECHLABS_URLS.getPlacedStudentsData,
            {
                headers:
                    { 'apikey': RJTECHLABS_URLS.API_KEY }
            })
            .then((res) => {
                if (res.data.status === 200) {
                    console.log("res.data", res.data.data);
                    setStudentsList(res?.data?.data);
                }
                else {
                }
            })
            .catch(function (e) {
            })
    }
    return (
        <>
            <section class="special py-80">
                <div class="container-fluid">
                <h2 class="black font-primary fw-600 text-center">Our Students Placed</h2>
                    <div class="row row-gap-4 mt-4">
                        {studentsList && studentsList.length >0 &&
                            studentsList.map((x, index) => {
                                return (
                                    <div class="col-xl-3 col-lg-6 col-sm-6"  key={index + 1}>
                                        <div class="special-item bg-light" style={{ boxShadow: '10px 15px 10px rgba(0.2, 0.2, 0.2, 0.2)', border: '#fff' }}>
                                                <img
                                                    src={x?.PlacedCompanyImage}
                                                    alt="Persuasion Masterclass: How To Powerfully Influence Anyone"
                                                    loading="lazy" height={100} width={100} />
                                                <div class="card-body">
                                                    <h5 class="">
                                                        <a >{x?.PlacedStudentName} </a>
                                                    </h5>
                                                    <a class="tutor-meta-value mt-4 black"> <i class="far fa-user"></i> {x?.PlacedStudentPackage}</a>
                                                </div>
                                            </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </section>
        </>
    )
}

export default OurStudents