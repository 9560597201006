import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { RJTECHLABS_URLS } from '../../utills/url';
import List from './courses.json'

const Courses = () => {
    const navigate = useNavigate();

    const [coursesList, setCoursesList] = useState([])
    const [allCourses, setAllCourses] = useState([])


    useEffect(() => {
        setCoursesList(List)
    }, [])

    const handleFilterChange = (event) => {
        const selectedValue = event.target.value;

        if (selectedValue === '001') {
            setCoursesList(List);
        } else {
            const filtered = List.filter(course => course.CoursesMasterID.toString() === selectedValue);
            setCoursesList(filtered);
        }
    };

    useEffect(() => {
        getAllCourses()
    }, [])

    const currencies = [
        {
            value: 2,
            label: 'All'
        },

        {
            value: 1,
            label: 'Active'
        },
        {
            value: 0,
            label: 'In-Active'
        }

    ];
    const getAllCourses = async () => {
        await axios.get(RJTECHLABS_URLS.getAllCoursesApprovalDetails + 1,
            {
                headers:
                    { 'apikey': RJTECHLABS_URLS.API_KEY }
            })
            .then((res) => {
                if (res.data.status == 200) {
                    console.log("res.data", res?.data?.data);
                    setAllCourses(res?.data?.data);
                }
                else {
                }
            })
            .catch(function (e) {
            })
    }


    return (
        <>
            <section class="title-banner text-center">
                <div class="container-fluid">
                    <h1 class="color-white fw-400">Courses</h1>
                </div>
            </section>
            <section>
                <div className='mt-4'>
                    <marquee>
                        <h5 className='color-sec'>  New batches started today, hurry up to contact us now!</h5>
                    </marquee>
                </div>
            </section>
            <div class="special py-80">
                <div class="container-fluid">
                    <div class="row row-gap-4">
                        {allCourses && allCourses.length != 0 ? (
                            allCourses.map((x, index) => {
                                return (
                                    <div class="col-xl-3 col-lg-6 col-sm-6 " key={index + 1}>
                                        <div class="special-item p-24" style={{ height: '100%', boxShadow: '0px 0px 10px 5px rgba(0, 0, 0, 0.2)', border: '1px solid #fff' }}>
                                            <div class="item-image">
                                                <a >
                                                    <img src={x?.CoursesImage} alt="" />
                                                </a>
                                            </div>
                                            <div class="item-detail">
                                                <div class="rating mb-16">
                                                    <i class="fa-solid fa-star-sharp"></i>
                                                    <i class="fa-solid fa-star-sharp"></i>
                                                    <i class="fa-solid fa-star-sharp"></i>
                                                    <i class="fa-solid fa-star-sharp"></i>
                                                    <i class="fa-solid fa-star-sharp"></i>
                                                </div>
                                                <a href="product-detail.html" class="h6 black color-white mb-8">
                                                    {x?.CoursesName}
                                                </a>
                                                <p class="text-16 light-gray mb-24">{x?.CoursesDescrption}</p>
                                                <div class="d-flex align-items-center justify-content-between">
                                                    {/* <span class="h6 color-sec">$20.00</span> */}
                                                    <a class="cus-btn transparent textd-16 p-8-16"
                                                        onClick={() => {
                                                            navigate('/coursesDetails', { state: x })
                                                        }}>
                                                        Enroll Now
                                                        <i class="far fa-shopping-cart"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div>No Data Found </div>
                        )}
                    </div>
                </div>
            </div>

        </>
    )
}

export default Courses