import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { RJTECHLABS_URLS } from '../../utills/url';

const Features = () => {
    const navigate = useNavigate();
    const [about, setAbout] = useState([]);

    useEffect(() => {
        getGreatFeatures();
    }, []);



    const getGreatFeatures = async () => {
        // setLoading(true)
        await axios.get(RJTECHLABS_URLS.getGreatFeatures,
            {
                headers:
                    { 'apikey': RJTECHLABS_URLS.API_KEY }
            })
            .then((res) => {
                if (res.data.status === 200) {
                    console.log("res.data", res.data.data);
                    setAbout(res.data.data);
                }
                else {
                }
            })
            .catch(function (e) {
            })
    }
    return (
        <section class="category py-80">
            <div class="container-fluid">
                <div class="row row-gap-4">
                    <h2 class="black font-primary fw-600 text-center">Some great features of RJ Tech Labs</h2>
                    {about && about.length > 0 && about.map((items, index) => {
                        return <div class="col-xl-4 col-lg-6 col-sm-6" key={index + 1}>
                            <div class="category-item">
                                <img src={items?.GreatFeaturesImage ?? "assets/media/category/4.jpg"} alt="" />
                                <div class="item-detail p-24 d-flex flex-column align-items-center gap-16 text-center">
                                    <h5 class="color-white text-uppercase"> {items?.GreatFeaturesTitleName}</h5>
                                    <p class="text-16 color-white">{items?.GreatFeaturesDesc}</p>
                                    <a class="cus-btn transparent text-16" onClick={() => navigate('/contact')}>
                                        CONTACT US
                                    </a>
                                </div>
                            </div>
                        </div>
                    })}

                    {/* <div class="col-xl-4 col-lg-6 col-sm-6">
                        <div class="category-item">
                            <img src="assets/media/category/5.jpg" alt="" />
                            <div class="item-detail p-24 d-flex flex-column align-items-center gap-16 text-center">
                                <h5 class="color-white text-uppercase">Extensive Practice</h5>
                                <p class="text-16 color-white">Key aspect of our training methodology; examples, assignments, project tasks.</p>
                                <a class="cus-btn transparent text-16" onClick={() => navigate('/contact')}>
                                    CONTACT US
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6">
                        <div class="category-item">
                            <img src="assets/media/category/6.jpg" alt="" />
                            <div class="item-detail p-24 d-flex flex-column align-items-center gap-16 text-center">
                                <h5 class="color-white text-uppercase">Job Assurance</h5>
                                <p class="text-16 color-white">Friendly tech support to troubleshoot issues during your practice sessions</p>
                                <a class="cus-btn transparent text-16" onClick={() => navigate('/contact')}>
                                    CONTACT US
                                </a>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>


        </section>
    )
}

export default Features